@use "sass:math";
@import "./colors";
@import "./fonts";

/*
Template Name: Osahan Eat - Online Food Ordering Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
-- Body
-- Nav
-- login/Register
-- Homepage Search Block
-- Homepage
-- Customize Bootstrap
-- Listing
-- Detail
-- Checkout Page
-- My Account
-- Track Order Page
-- Footer
-- Mobile Media
*/
/* Body */
body {
  font-family: "Arial Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  background: $secondaryColor;
  background-size: 50%;
  font-size: 13px;
  color: $textLight;
  height: auto;
  overflow: auto;
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
    height: 100%;
  }
}

.nexa {
  font-family: 'Nexa Script', cursive;
}

.bg-primary {
  background: $primary;
}

.bg-primaryLight {
  background: $primaryLight;
}

.bg-primaryLight2 {
  background: $primaryLight2;
}

.btn {
  padding-left: 20px;
  padding-right: 20px;
}

button, a {
  outline: none !important;
  color: $primary;
  text-decoration: none !important;
}

p {
  font-size: 13px;
  color: $textLight;
}

img {
  image-rendering: auto;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  color: $text;
  font-family: "Arial Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
}

.h1, .h2, .h3, .h4,
h1, h2, h3, h4 {
}

h1 {
  font-size: 46px;
  line-height: 52px;
}

h2 {
  font-size: 36px;
  line-height: 42px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}


/* Nav */
.osahan-nav {
  background: #FFF none repeat scroll 0 0 !important;
  padding: 0;
  z-index: 9999999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.osahan-nav .nav-link {
  font-weight: 600;
  padding: 28px 0 !important;
  margin: 0 0 0 31px;
}

.dropdown-cart .dropdown-menu {
  padding-top: 0 !important;
}

.nav-osahan-pic {
  width: 32px;
  height: 32px;
  border: 3px solid $white;
  position: relative;
  margin: -8px 5px -6px 0;
  vertical-align: text-top;
}

.dropdown-cart-top {
  border-top: 2px solid $primary;
  min-width: 420px;
}

.dropdown-cart-top-header {
  background: $primaryLight2;
}

.dropdown-cart-top-header .img-fluid {
  border: 1px solid #DCDCDC;
  border-radius: 3px;
  float: left;
  height: 60px;
  padding: 3px;
  width: 80px;
  object-fit: contain;
  background-color: #FFF;
}

.dropdown-cart-top-header p.text-secondary {
  font-size: 11px;
  line-height: 24px;
  font-weight: 600;
}

.dropdown-cart-top-header h6 {
  font-size: 14px;
}

.food-item {
  border: 1px solid;
  border-radius: 2px;
  display: inline-block;
  font-size: 6px;
  height: 13px;
  line-height: 12px;
  text-align: center;
  width: 13px;
}

.dropdown-cart-top-body .food-item {
  float: left;
  margin: 4px 10px 0 0;
  padding-left: 1px;
}

/* login/Register */
:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}

.login,
.image-fullscreen {
  min-height: 100vh;
}

.bg-image {
  background-size: cover;
  background-position: right;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: 15px 0;
  border-radius: 0;
  height: 51px;
  background: transparent !important;
  border: none;
  border-bottom: 1px solid #CED4DA;
  box-shadow: none !important;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.headerForm .form-label-group input:not(:placeholder-shown) ~ label {
  top: -35%;
  bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
  padding-bottom: 0;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  opacity: 0;
  top: -10px;
  bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.form-control:focus {
  border-color: $primaryNeon;
}

.btn-google {
  color: white;
  background-color: #EA4335;
}

.btn-facebook {
  color: white;
  background-color: #3B5998;
}

/* Homepage Search Block */
.homepage-search-block {
  background-color: $lightGreenBackground;
  background-size: cover;


}

.homepage-search-form .form-control {
  font-size: 15px;
  height: 50px;
  padding-right: 106px;
}

.homepage-search-form .form-group {
  position: relative;
}

.homepage-search-form .btn {
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  padding: 13px 5px;
}

.homepage-search-form .location-dropdown i {
  left: 18px;
  position: absolute;
  top: 20px;
  z-index: 9;
}

.homepage-search-form .select2-selection {
  border-color: #CED4DA !important;
  border-radius: 0.25rem !important;
  height: 50px !important;
  outline: none !important;
}

.homepage-search-form .select2-selection__rendered {
  font-size: 15px;
  line-height: 47px !important;
  padding: 0 28px 0 31px !important;
}

.homepage-search-form .select2-selection__arrow {
  right: 9px !important;
  top: 13px !important;
}

.homepage-search-form {
  .location-search-input {
    border-radius: 30px;
  }

  .home-search-link-bar {
    @media(max-width: 575px) {
      //display         : flex;
      //flex-wrap       : wrap;
      //justify-content : space-between;
    }

    a.btn-outline-primary {
      min-width: 100px;
      text-align: center;
      font-size: 0.85rem;
      padding: 5px 10px;
      height: auto !important;
      margin-right: 7px;
      @media(max-width: 575px) {
        background-color: #F1F4E0;
        margin-top: 2.5px;
        margin-bottom: 2.5px;
      }
    }
  }
}


.select2-dropdown {
  margin: -2px 0 0 0;
  border: 1px solid #CED4DA;
  border-radius: 3px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #CED4DA;
}

.select2-search__field {
  outline: none !important;
  border: 1px solid #CED4DA;
}

.owl-carousel {
  position: relative;

  &#thumbs {
    .owl-stage-outer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &.single-image {
      .owl-stage-outer {
        display: none;
      }
    }
  }
}

.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  bottom: 50%;
  background: $white none repeat scroll 0 0 !important;
  border-radius: 30px !important;
  color: #000 !important;
  height: 25px;
  line-height: 25px;
  margin: 0 !important;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.owl-theme .owl-nav [class*="owl-"] i {
  font-size: 15px !important;
  font-weight: normal !important;
}

.owl-theme .owl-nav .owl-prev {
  margin-left: -16px !important;

  i {
    margin-right: 2px;
  }
}

.owl-theme .owl-nav .owl-next {
  right: 0;
  margin-right: -16px !important;

  i {
    margin-left: 2px;
  }
}

/* Homepage */
.owl-carousel-four .item {
  padding: 6px 7px;
}

.category-item {
  background: $white;
  margin: 0 2px 5px 2px;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  padding: 7px;
  border: 1px solid $white;

  &:hover {
    border: 1px solid $primary;
  }

  a:hover, a:hover h6 {
    color: $primaryLight !important;
  }

  h6 {
    margin: 0;
    font-size: 13px;
    color: $primary;
    font-weight: 500;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  img {
    width: 40px !important;
    height: 40px;
    object-fit: cover;
    display: inline-block !important;
    border-radius: 50px;
    margin: 2px 0 8px 0;
  }

  i {
    font-size: 2rem !important;
  }

  i {
    vertical-align: center;
    text-align: center;
    font-size: 24px;
    padding: 8px;
  }
}

.line {
  background-color: #5E5E5E;
  border-radius: 12px;
  display: inline-table;
  height: 4px;
  margin: 10px 0 51px;
  width: 50px;
}

/* Customize Bootstrap */
.small, small {
  font-size: 85%;
  font-weight: 600;
}

.select2, .select2 span {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  left: 96%;
}

.select2-container--default .select2-selection--single {
  background-color: $white;
  border: 1px solid #CED4DA;
  border-radius: 3px;
}

.modal-footer .btn {
  font-size: 14px;
  padding: 12px 16px;
}

.btn-success,
.badge-success {
  background-color: $primaryLight !important;
  border-color: $primaryLight !important;
}

.btn-outline-success {
  color: $primaryLight !important;
  border-color: $primaryLight !important;
}

.btn-outline-success:hover {
  color: $white !important;
  border-color: $primaryLight !important;
  background-color: $primaryLight !important;
}

.text-success {
  color: $primaryLight !important;
}

.border-success {
  border-color: $primaryLight !important;
}

.btn-danger,
.badge-danger {
  background-color: $danger !important;
  border-color: $danger !important;
}

.btn-outline-danger {
  color: $danger !important;
  border-color: $danger !important;
}

.btn-outline-danger:hover {
  color: $white !important;
  border-color: $danger !important;
  background-color: $danger !important;
}

.text-danger {
  color: $danger !important;
}

.border-danger {
  border-color: $danger !important;
}

.badge-danger {
  background-color: $danger !important;
  border-color: $danger !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.badge-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $white !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.btn-lg {
  font-size: 15px;
  font-weight: bold;
  padding: 14px 35px;
  letter-spacing: 1px;
  border-radius: 40px !important;
}

.modal-content {
  border: none;
  border-radius: 2px;
}

.btn {
  border-radius: 20px;
}

.btn-link {
  color: $primary;
}

.form-control {
  font-size: 13px;
  border-radius: 2px;
  min-height: 38px;
}

.card {
  border-radius: 2px;
}

.rounded {
  border-radius: 20px !important;
}

.input-group-text {
  border-radius: 2px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.alert,
.badge,
.dropdown-menu {
  border-radius: 20px;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: 2px;
}

.bg-dark {
  background-color: #000 !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4rem !important;
}

.dropdown-menu {
  font-size: 13px;
}

.dropdown-item {
  border-radius: 0 !important;
  padding: 7px 18px;
  color: rgba(0, 0, 0, .5);
  font-weight: 500;
}

.dropdown-item:focus {
  background-color: $primary;
  color: $white;
}

/* Listing */
.list-cate-page {
  margin: -5px 0px 0px 0px;
}

.filters-card-body {
  padding: 18px 0 0 0;
}

.filters-search {
  position: relative;
}

.filters-search i {
  position: absolute;
  left: 12px;
  top: 13px;
}

.custom-checkbox label.custom-control-label,
.custom-radio label.custom-control-label {
  padding-top: 2px;
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $primaryNeon;
  background-color: $primaryNeon;
}

.filters-card-header a {
  font-weight: 500;
  width: 100%;
  display: block;
}

.filters-card-header a i {
  margin: 2px -2px 0 0;
}

.position-absolute {
  z-index: 1;
}

iframe.position-absolute {
  z-index: 0;
}

.list-card .count-number {
  margin: 4px 0 0 0;
  display: inline-block;
}

.list-card a {
  color: $primary;
  word-break: break-word;
  white-space: break-spaces;
}

.list-card:not(.teaser-item) p {
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.list-card a:hover {
  color: #0A5DC2;
}

.list-card .star {
  right: 8px;
  bottom: 8px;
}

.list-card .star .badge {
  font-size: 11px;
  padding: 5px 5px;
}

.list-card .member-plan {
  left: 8px;
  top: 8px;
}

.list-card .member-plan .badge {
  font-size: 11px;
  padding: 5px 5px;
}

.list-card .list-card-image {
  position: relative;
}

.list-card .favourite-heart {
  right: 8px;
  top: 8px;
}

.list-card .favourite-heart i {
  background: $white;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  border-radius: 50px;
}

/* detail */
.count-number .btn {
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 0;
}

.restaurant-detailed-header-left {

}

.count-number-input {
  width: 24px;
  text-align: center;
  margin: 0 -4px;
  background: #6C757D;
  border: none;
  color: $white;
  height: 24px;
  border-radius: 0;
  vertical-align: bottom;
}

.generator-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff3008+1,fe792b+100 */
  background: $primary; /* Old browsers */
  background: -moz-linear-gradient(-45deg, $primary 1%, $primaryLight 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, $primary 1%, $primaryLight 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, $primary 1%, $primaryLight 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$primary, endColorstr=$primaryLight, GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.restaurant-detailed-banner {
  position: relative;
}

.restaurant-detailed-banner .img-fluid.cover {
  height: 424px;
  object-fit: cover;
  width: 100%;
}

.restaurant-detailed-header {
  bottom: 0;
  left: 0;
  padding: 65px 0 17px;
  position: absolute;
  right: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}

.homepage-great-deals-carousel .item img {
  border-radius: 2px;
}

.restaurant-detailed-earn-pts .img-fluid.float-left {
  height: 61px;
}

.card-icon-overlap {
  overflow: hidden;
  position: relative;
}

.red-generator-bg {
  background: #FF3371;
  background: -moz-linear-gradient(-45deg, #FF3371 1%, #FE6753 100%);
  background: -webkit-linear-gradient(-45deg, #FF3371 1%, #FE6753 100%);
  background: linear-gradient(135deg, #FF3371 1%, #FE6753 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3371', endColorstr='#fe6753', GradientType=1);
}

.border-btn {
  border: 1px solid #DEE0E6;
  border-radius: 2px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  margin: 0 0 10px 0;
}

.restaurant-detailed-ratings .generator-bg {
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  margin: 0 4px 0 0;
  padding: 3px 6px;
}

.explore-outlets-search .form-control {
  border-radius: 2px !important;
  font-size: 15px;
  height: 50px;
  padding: 5px 20px;
}

.explore-outlets-search .btn {
  color: #777;
  font-size: 15px;
  padding: 13px 17px;
  position: absolute;
  z-index: 9;
  right: 0;
  text-decoration: none;
}

.mall-category-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 0 6px;
  overflow: hidden;
  text-align: center;
  padding: 0 0 7px 0;
}

.mall-category-item img {
  height: 89px;
  object-fit: cover;
  width: 100%;
}

.mall-category-item h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 10px 10px 3px 10px;
}

.mall-category-item .btn {
  padding: 4px;
  line-height: 12px;
  font-size: 12px;
  right: 7px;
  top: 7px;
}

.mall-category-item small {
  background: #ECECEC;
  color: #F32129;
  padding: 1px 6px;
  border-radius: 2px;
  font-size: 10px;
}

.mall-category-item:hover small {
  background: #F32129;
  color: $white;
}

.address-map {
  border-radius: 2px;
  overflow: hidden;
}

.new-arrivals-card .card-img .new-arrivals {
  bottom: 11px;
  left: 12px;
  position: absolute;
}

.new-arrivals {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  color: $white;
  padding: 1px 13px;
  text-transform: uppercase;
}

.new-arrivals-card .card-img {
  position: relative;
}

.total-like-user {
  border: 2px solid $white;
  height: 34px;
  width: 34px;
}

.total-like-user-main a {
  display: inline-block;
  margin: 0 -17px 0 0;
}

.total-like {
  border: 1px solid;
  border-radius: 50px;
  display: inline-block;
  font-weight: 500;
  height: 34px;
  line-height: 33px;
  padding: 0 13px;
  vertical-align: top;
}

.restaurant-detailed-ratings-and-reviews hr {
  margin: 0 -24px;
}

.star-rating {
  cursor: pointer;
}

.graph-star-rating-header .star-rating {
  font-size: 17px;
}

.progress {
  background: #F2F4F8 none repeat scroll 0 0;
  border-radius: 0;
  height: 30px;
}

.rating-list {
  display: inline-flex;
  margin-bottom: 15px;
  width: 100%;
}

.rating-list-left {
  height: 16px;
  line-height: 29px;
  width: 10%;
}

.rating-list-center {
  width: 80%;
}

.rating-list-right {
  line-height: 29px;
  text-align: right;
  width: 10%;
}

.restaurant-slider-pics {
  bottom: 0;
  font-size: 12px;
  left: 0;
  z-index: 999;
  padding: 0 10px;
}

.restaurant-slider-view-all {
  bottom: 15px;
  right: 15px;
  z-index: 999;
}

.offer-dedicated-nav .nav-link.active,
.offer-dedicated-nav .nav-link:hover,
.offer-dedicated-nav .nav-link:focus {
  border-color: $primary;
  color: $primary;
}

.offer-dedicated-nav .nav-link {
  border-bottom: 2px solid $white;
  color: #000;
  padding: 16px 0;
  font-weight: 600;
}

.offer-dedicated-nav .nav-item {
  margin: 0 37px 0 0;
}

.restaurant-detailed-action-btn {
  margin-top: 12px;
}

.restaurant-detailed-header-right .btn-primary {
  border-radius: 40px;
  height: 45px;
  margin: -18px 0 18px;
  min-width: 130px;
  padding: 7px;
}

.text-black {
  color: $textLight;
}

.icon-overlap {
  bottom: -23px;
  font-size: 74px;
  opacity: 0.23;
  position: absolute;
  right: -32px;
}

.menu-list img {
  width: 41px;
  height: 41px;
  object-fit: cover;
}

.restaurant-detailed-header-left img {
  height: 100%;
  max-height: 100px;
  max-width: 200px;
  border-radius: 3px;
  object-fit: contain;
}

/* Checkout Page */
.addresses-item a.btn.btn-sm {
  min-width: 157px;
}

.osahan-cart-item-profile img {
  width: 40px;
  height: 40px;
  border: 3px solid $white;
}

.osahan-cart-item-profile p {
  font-size: 10px;
}

.osahan-payment .nav-link {
  padding: 18px 20px;
  border: none;
  font-weight: 600;
  color: #535665;
}

.osahan-payment .nav-link i {
  width: 28px;
  height: 28px;
  background: #535665;
  display: inline-block;
  text-align: center;
  line-height: 29px;
  font-size: 15px;
  border-radius: 50px;
  margin: 0 7px 0 0;
  color: $white;
}

.osahan-payment .nav-link.active {
  background: $lightGreenBackground;
  color: #282C3F !important;
}

.osahan-payment .nav-link.active i {
  background: #282C3F !important;
}

.osahan-payment .tab-content {
  background: $lightGreenBackground;
  padding: 20px;
}

.osahan-card i {
  font-size: 35px;
  vertical-align: middle;
  color: #6C757D;
}

.osahan-card {
  margin: 0 0 0 7px;
}

.osahan-payment label {
  font-size: 12px;
  margin: 0 0 3px 0;
  font-weight: 600;
}

/* My Account */
.payments-item img.mr-3 {
  width: 47px;
}

.order-list .btn {
  border-radius: 2px;
  min-width: 121px;
  font-size: 13px;
  padding: 7px 0 7px 0;
}

.osahan-account-page-left {
  .nav-link {
    padding: 18px 20px;
    border: none;
    font-weight: 600;
    color: #535665;

    i {
      width: 32px;
      height: 32px;
      background: #535665;
      display: inline-block;
      text-align: center;
      line-height: 16px;
      padding: 8px;
      font-size: 16px;
      border-radius: 50px;
      margin: 0 7px 0 0;
      color: #F2F2F2;

      &:before {
        text-align: center;
      }
    }


    &.active {
      color: $primary !important;

      i {
        background: $primary !important;
      }
    }
  }
}

.osahan-user-media img {
  width: 90px;
}

.card.offer-card h5.card-title {
  border: 2px dotted #000;
}

.card.offer-card h5 {
  border: 1px dotted #DACEB7;
  display: inline-table;
  color: #17A2B8;
  margin: 0 0 19px 0;
  font-size: 15px;
  padding: 6px 10px 6px 6px;
  border-radius: 2px;
  background: #FFFAE6;
  position: relative;
}

.card.offer-card h5 img {
  height: 22px;
  object-fit: cover;
  width: 22px;
  margin: 0 8px 0 0;
  border-radius: 2px;
}

.card.offer-card h5:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #DACEB7;
  content: "";
  left: 30px;
  position: absolute;
  bottom: 0;
}

.card.offer-card h5:before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #DACEB7;
  content: "";
  left: 30px;
  position: absolute;
  top: 0;
}

.card.offer-card .card-btn {
  font-size: 13px;
  color: $primary;
  vertical-align: top !important;
  border: 0 !important;
}

.card.offer-card .card-btn:hover {
  color: #0056B3;
}

.payments-item .media {
  align-items: center;
}

.payments-item .media img {
  margin: 0 40px 0 11px !important;
}

.reviews-members .media .mr-3 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.order-list img.mr-4 {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 2px;
}

.osahan-cart-item p.text-gray.float-right {
  margin: 3px 0 0 0;
  font-size: 12px;
}

.osahan-cart-item .food-item {
  vertical-align: bottom;
}

/* Track Order Page */

.osahan-track-order-page {
  min-height: 676px;
}

.osahan-point {
  z-index: 1;
  width: 50%;
  border-radius: 50%;
  padding-bottom: 50%;
  pointer-events: none;
  background-color: transparentize($primary, 0.8);
  border: 1px solid $primary;
}

/* Footer */
.footer {
  background: $primary;
}

.footer h6 {
  font-size: 14px;
  text-transform: uppercase;
}

.footer ul {
  margin: 0;
  padding: 0;
}

.footer ul li {
  line-height: 26px;
  list-style: outside none none;
}

.footer ul li a {
  color: #6C8293;
}

.search-links a {
  color: #666;
  font-size: 12px;
  line-height: 23px;
  margin: 0 2px;
}

.search-links {
  font-size: 11px;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .m-none {
    display: none;
  }

  .homepage-search-title {
    text-align: center;
  }

  .list-cate-page {
    margin: 22px 0 0 0;
  }

  .d-none-m {
    display: none;
  }

  .row.pt-2.pb-2 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .row.pt-2 {
    padding-top: 0 !important;
  }

  .offer-card-horizontal .col-md-4.col-4.p-4 {
    padding: 0 0 0 15px !important;
  }

  .row.mt-4.pt-2 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .homepage-add-section {
    padding: 0 13px;
  }


  .breadcrumb-osahan h1 {
    font-size: 30px;
    line-height: 36px;
  }


  .breadcrumb-osahan h6 {
    font-size: 15px;
  }

  .breadcrumb-osahan {
    padding-left: 15px;
    padding-right: 15px;
  }

  .reviews-members .media .mr-3 {
    width: 36px;
    height: 36px;
  }

  .total-like {
    font-size: 12px;
  }

  .restaurant-detailed-action-btn.float-right .btn {
    margin: 3px 0;
    font-size: 13px;
  }

  .login .col-md-9.col-lg-8.mx-auto.pl-5.pr-5 {
    padding: 0 11px !important;
  }

  .login.d-flex.align-items-center.py-5 {
    padding: 0 !important;
  }

  .h5, h5 {
    font-size: 16px;
  }

  .homepage-add-section .col-md-3.col-6 {
    padding: 2px 2px;
  }

  .homepage-ad .item {
    padding: 0 2px;
  }

  .osahan-nav {
    padding: 15px 15px;
  }

  .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
  }

  .osahan-nav .nav-link {
    margin: 0;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
    text-align: center;
  }

  .osahan-slider {
    padding: 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
    padding-top: 3rem !important;
  }

  .pb-5, .py-5 {
    padding-bottom: 3rem !important;
  }

  .homepage-search-block {
    padding: 3rem 0 !important;
  }

  .mobile-none {
    display: none;
  }

  .card {
    margin-bottom: 15px !important;
  }

  p {
    font-size: 12px;
  }

  .restaurant-detailed-header-left img {
    margin: 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
    text-align: center;
  }

  .restaurant-detailed-header-right .btn-primary {
    margin-top: 18px;
  }

  .restaurant-detailed-header-right.text-right {
    text-align: center !important;
  }

  .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    width: 100% !important;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .offer-dedicated-nav .nav-item {
    flex: auto;
    border-bottom: 1px solid #CCC;
    padding: 0;
    margin: 0 11px !important;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
    width: 100%;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
    padding-left: 0 !important;
  }

  .osahan-account-page-left {
    height: auto !important;
    margin: 0 0 15px 0;
  }

  .order-list .float-right {
    float: none !important;
  }

  .row.mb-4 {
    margin-bottom: 0 !important;
  }

  .app {
    margin-bottom: 26px;
  }

  .footer ul li a {
    font-size: 12px;
  }

  .footer h6 {
    font-size: 13px;
    text-transform: capitalize;
  }

  .osahan-payment .col-sm-4.pr-0 {
    padding-right: 15px !important;
  }

  .osahan-payment .col-sm-8.pl-0 {
    padding-left: 15px !important;
    margin: 14px 0 0 0;
  }

  .p-5.osahan-invoice.bg-white.shadow-sm {
    padding: 15px !important;
  }

  .h3, h3 {
    font-size: 22px;
  }

  .p-5 {
    padding: 20px !important;
  }

  .osahan-account-page-right {
    padding: 0 !important;
    border: none;
    background: transparent !important;
    box-shadow: none !important;
    margin-top: 15px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .homepage-add-section .col-md-3.col-6 {
    padding: 2px 2px;
  }

  .homepage-search-title {
    text-align: center;
  }

  .row.pt-2.pb-2 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .row.pt-2 {
    padding-top: 0 !important;
  }

  .d-none-m {
    display: none;
  }

  .list-cate-page {
    margin: 22px 0 0 0;
  }

  .row.mt-4.pt-2 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .offer-card-horizontal .col-md-4.col-4.p-4 {
    padding: 0 0 0 15px !important;
  }

  .homepage-add-section {
    padding: 0 13px;
  }

  .homepage-ad .item {
    padding: 0 2px;
  }

  .container {
    max-width: 100%;
  }

  .osahan-nav {
    padding: 15px 15px;
  }

  .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
  }

  .osahan-nav .nav-link {
    margin: 0;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
    text-align: center;
  }

  .osahan-slider {
    padding: 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
    padding-top: 3rem !important;
  }

  .pb-5, .py-5 {
    padding-bottom: 3rem !important;
  }

  .homepage-search-block {
    padding: 3rem 0 !important;
  }

  .mobile-none {
    display: none;
  }

  .card {
    margin-bottom: 15px !important;
  }

  p {
    font-size: 12px;
  }

  .restaurant-detailed-header-left img {
    margin: 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
    text-align: center;
  }

  .restaurant-detailed-header-right .btn-primary {
    margin-top: 18px;
  }

  .restaurant-detailed-header-right.text-right {
    text-align: center !important;
  }

  .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    width: 100% !important;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .offer-dedicated-nav .nav-item {
    flex: auto;
    border-bottom: 1px solid #CCC;
    padding: 0;
    margin: 0 11px !important;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
    width: 100%;
  }

  .osahan-payment .nav-link i {
    display: block;
    margin: 0 auto 10px auto;
  }

  .osahan-payment .nav-link {
    text-align: center;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
    padding-left: 0 !important;
  }

  .osahan-account-page-left {
    height: auto !important;
    margin: 0 0 15px 0;
  }

  .order-list .float-right {
    float: none !important;
  }

  .row.mb-4 {
    margin-bottom: 0 !important;
  }

  .app {
    margin-bottom: 26px;
  }

  .footer ul li a {
    font-size: 12px;
  }

  .footer h6 {
    font-size: 13px;
    text-transform: capitalize;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    max-width: 100%;
  }

  .osahan-nav {
    padding: 15px 15px;
  }

  .navbar-toggler {
    padding: 5px 8px;
    border-radius: 2px;
  }

  .osahan-nav .nav-link {
    margin: 0;
    text-align: center;
    padding: 14px 0 !important;
    border-bottom: 1px solid $lightGreenBackground;
  }

  .osahan-nav .dropdown-item {
    text-align: center;
  }

  .osahan-slider {
    padding: 18px 0 0 0 !important;
  }

  .pt-5, .py-5 {
    padding-top: 3rem !important;
  }

  .pb-5, .py-5 {
    padding-bottom: 3rem !important;
  }

  .homepage-search-block {
    padding: 3rem 0 !important;
  }

  .card {
    margin-bottom: 15px !important;
  }

  p {
    font-size: 12px;
  }

  .restaurant-detailed-header-left img {
    margin: 0 0 14px 0 !important;
  }

  .restaurant-detailed-header-left {
    text-align: center;
  }

  .restaurant-detailed-header-right .btn-primary {
    margin-top: 18px;
  }

  .restaurant-detailed-header-right.text-right {
    text-align: center !important;
  }

  .restaurant-detailed-action-btn.float-right {
    float: none !important;
    margin: auto;
    text-align: center;
    width: 100% !important;
    display: block;
    padding: 12px 0;
    border-bottom: 1px solid #DEDEDE;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .offer-dedicated-nav .nav-item {
    margin: 0 8px !important;
  }

  .offer-dedicated-nav {
    text-align: center;
  }

  .address-map.float-right.ml-5 {
    float: none !important;
    margin: 0 0 20px 0 !important;
  }

  .address-map.float-right.ml-5 iframe {
    width: 100%;
  }

  .osahan-payment .nav-link i {
    display: block;
    margin: 0 auto 10px auto;
  }

  .osahan-payment .nav-link {
    text-align: center;
  }

  .osahan-track-order-detail p.text-gray.mb-5 {
    margin: 1px 0 11px 0 !important;
  }

  .osahan-account-page-left .nav-tabs {
    padding-left: 0 !important;
  }

  .osahan-account-page-left {
    height: auto !important;
    margin: 0 0 15px 0;
  }

  .order-list .float-right {
    float: none !important;
  }

  .row.mb-4 {
    margin-bottom: 0 !important;
  }

  .app {
    margin-bottom: 26px;
  }

  .footer ul li a {
    font-size: 12px;
  }

  .footer h6 {
    font-size: 13px;
    text-transform: capitalize;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $primaryLight;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container {
    max-width: 100%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.input-foreground {
  position: relative;
  z-index: 2;
}

.input-foreground:-webkit-autofill,
.input-foreground:-webkit-autofill:hover,
.input-foreground:-webkit-autofill:focus {
  border-bottom: 1px solid #CED4DA;
  -webkit-text-fill-color: $text;
  -webkit-box-shadow: transparent;
  background-color: transparent !important;
  transition: background-color 50000000s ease-in-out 0s;
}

.input-foreground:-webkit-autofill:focus {
  border-bottom: 1px solid #97BF04;
}

.plan-card {
  cursor: pointer;

  .card {
    height: 100%;
  }
}

.plan-card.active .card, .plan-card:hover .card {
  border: 1px solid $primary;
  background: mix($white, $primary, 95);
}

.plan-card:hover .card {
}


.my-uploadfield {
  .dzu-dropzone {
    border: 2px dashed $primaryLight;
    min-height: 100px;

    .dzu-inputLabel {
      margin: 0;
      font-weight: lighter;
      font-size: 1rem;
    }

    .dzu-previewContainer {
      border: none;
      min-height: 100px;
    }
  }

  .dzu-currentFiles {
    margin-top: 10px;
  }

  .dzu-previewContainer {
    border: 1px solid $primaryLight;
    padding: 10px;

    .dzu-previewStatusContainer {
      margin-right: 1rem;
      font-size: 1rem;
      color: $textLight;

      .dzu-previewButton {
        margin-top: -5px;
        margin-left: 2rem;
        min-height: 1rem;
        min-width: 1rem;
        color: $primary;
        padding: 0;

        &:hover {
          color: $danger;
        }
      }
    }

    .dzu-previewImage {
      max-height: 80px;
      max-width: 200px;
      border-radius: 0;
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
  color: $primary;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: $primary;
  color: #FFF;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: $primary;
  background: $primary;
  color: #FFF;
}

.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: $primary;
}

.ck-editor__editable {
  min-height: 250px;
  max-height: 400px;
  overflow: auto;

  .h2, .h3, .h4,
  h2, h3, h4 {
    font-family: "Arial Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.show.fade {
  opacity: 1;
  pointer-events: all;
}

.vendor-card-header {
  min-height: 100px;
}

.vendor-card-logo {
  img {
    border-radius: 3px;
    object-fit: contain;
    height: 100px;
  }
}

.customMarker {
  z-index: 10;
  background: #FFF;
  width: 250px;
  border-radius: 2px;

  padding-top: 40px !important;

  position: absolute;
  left: -135px;

  img {
    max-width: 100%;
    //height: 100%;
    //max-height: 60px;
    //max-width: 100px;
    //border-radius: 4px;
    //object-fit: contain;
    //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 10px);
    bottom: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #FFF;
    clear: both;

  }

  &:before {
    content: '';
    position: absolute;
    left: calc(50% - 11px);
    bottom: -22px;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-top: 22px solid rgba(0, 0, 0, 0.075);
    clear: both;
  }
}

.product-marker {
  padding-top: 0 !important;

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    height: 250px;
    border-radius: 0;
    object-position: center;
  }

  .closeMapPin {
    top: 0 !important;
    right: 0 !important;
    background-color: transparentize($white, 0.2);
    //border-radius: 50%;
  }
}

.clusterMarker {
  width: 350px;
  left: -185px;

  .img-container {
    height: 80px;
    overflow: hidden;
  }

  img {
    height: 80px;
    object-fit: contain;
  }

  .inner {
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.closeMapPin {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 2.25rem;
  cursor: pointer;
  color: $primaryGrey;
  z-index: 2;
}

.clusterNumber {
  position: absolute;
  background: $primaryLight;
  color: $primaryGrey;
  font-size: 14px;
  font-weight: bold;
  left: -15.56px;
  top: -63px;
  height: 31px;
  width: 31px;
  text-align: center;
  padding-top: 7.5px;
  border-radius: 50%;
  z-index: 9;
}

.pin-fill {
  position: absolute;
  background: $primaryLight;
  color: $primaryGrey;
  font-size: 15px;
  font-weight: bold;
  left: -16px;
  top: -64px;
  height: 32px;
  width: 32px;
  text-align: center;
  padding-top: 7px;
  border-radius: 50%;
  z-index: 0;
}

.map-pin {
  $map-pin-size: 80;

  position: absolute;
  color: $primaryGrey;

  font-size: ($map-pin-size * 1.109375)+px;
  left: (math.div($map-pin-size * 1.109375,2) * -1) + px;
  top: ($map-pin-size * -1)+px;
  height: ($map-pin-size)+px;
  width: ($map-pin-size * 1.109375)+px;
}

.addresses-item {
  img {
    max-width: 100px;
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.75);
}

.fade.in {
  opacity: 1 !important;
}


.product-card-img {
  object-fit: cover;
  height: 350px;
  width: 100%;
  object-position: center;
  /*width: 100%;
  object-position: top center;
  object-fit: cover;*/
}

.inlineLocationField {
  background: #FFF;
  padding: 11px 16px;
  font-size: 13px;
  border-radius: 20px;
  min-height: 38px;
}

.owl-carousel-category {
  .owl-stage {
    display: flex;
    justify-content: stretch;

    .item {
      height: 100%;

      .category-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: calc(100% - 5px);
      }
    }
  }
}

.select2-smaller {
  .select2 {
    max-width: 80px !important;
    margin-left: 15px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    left: 90%;
  }
}

.select2-container--open {
  z-index: 999999;
}

.card-overlay {
  background: #FFF;
  height: 100%;

  .close-card {
    text-align: left;

    i {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .basket-content {
    height: calc(100% - 200px);
    overflow: auto;
  }
}

.checkout-product-img {
  max-height: 50px;
  object-fit: contain;
}

.border-width-2 {
  border-width: 2px !important;
}

.address-select {
  z-index: 3;
}

.edit-button {
  background: #EEE;
  border-radius: 2px;
  padding: 7px 10px;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
}

.bigger-badge {
  font-size: 1rem;
  padding: 10px;
  display: inline-block;
}

.headerForm {
  min-width: 500px;
  max-Width: 100%;
  min-height: 74px;
}

.search-close {
  font-size: 2rem;
}

.category-list {
  list-style: none;
  padding-left: 20px;

  li:before {
    content: '\00BB';
    margin-left: -20px;
    margin-right: 10px;
  }
}

.columned-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-rule: 4px double #CCC;
  column-span: 4;
}

.custom-location-field {
  .locate-me {
    margin-top: -4px;
    margin-right: -4px;
  }
}

.text-shadow {
}

.rs-checkbox-wrapper::before {
  border: 1px solid #97BF04;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #97BF04;
  background-color: #97BF04;
}

.rs-check-tree {
  max-height: 100%;
  overflow: scroll;
}

.rs-slider-progress-bar {
  position: absolute;
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #97BF04;
}

.rs-slider-handle::before {
  border: 2px solid #97BF04;
}

.custom-label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
  margin-top: 10px;
}

.abo-info {
  border-top: 1px solid #7A7E8A;
  padding-top: 5px;
}

.smaller-map {
  max-height: 600px;
}

.form-check-paid {
  background: transparentize($primaryNeon, .7);
  padding: 1rem 0.75rem;

  .form-check-input {
    position: relative;
    margin: 0 0.5rem 0 0;
  }
}

.category-tag {
  font-size: 0.75rem;
}

.inlineTitleEditField {
  font-size: 1rem;
}

.text-primaryDark {
  color: $primaryDark;
}

.text-primaryNeon-green {
  color: $primaryNeon;
}

.text-primaryNeon-green-50 {
  color: transparentize($primaryNeon, 0.5);
}

.text-italic {
  font-style: italic;
}

.homepage-search-title {
  padding: 10px;
  color: $primaryDark;

  & > * {
    color: $primaryDark;
  }
}

.header-bg-green {
}

.header-bg {
}

.flex-owl {
  .owl-stage {
    display: flex !important;
  }
}

.list-card {
  border: 1px solid $brown;
}

.shadow-sm {
  box-shadow: none !important;
}

.form-control {
  border-radius: 20px;
}

.header-bg {
  h1 {
    white-space: pre-line;
    word-break: break-word;
  }
}

.bg-photo {
  padding: 0 !important;
  overflow: hidden;

  & > div {
    height: 100%;
    width: 100%;
    padding: 8vh 0;
    background: rgba(0, 0, 0, 0.5);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .4) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, .4)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 100%);
  }

  .text-container {
    position: relative;
    z-index: 1;
    max-width: 600px;
    display: inline-block;
    word-break: break-all;
  }

  .middle-circle {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 600px;
    width: 600px;
    border-radius: 50%;
    //background: rgba(0, 0, 0, 0.3);
    //background: -o-radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.0) 100%);
    //background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.0) 100%);
    background: rgba(255, 255, 255, 0.3);
    background: -o-radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.05) 100%);
    background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.05) 100%);
  }

  & * {
    color: $white !important;
  }
}


.bookmark-card {
  font-size: 2rem;
  text-align: right;
  cursor: pointer;
}

.osahan-nav .nav-link.icon-only {
  margin-left: 1rem !important;
  font-size: 1.25rem;
  padding: 20px 0 !important;

  i {
    margin-top: 5px;
  }

  .badge {
    font-size: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding-top: 5px;
    text-align: center;
    margin-top: -10px;
  }
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}

.mobile-nav {
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  background: #FFF;
  max-height: calc(100vh - 300px);
  overflow: auto;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    text-align: left;
    font-size: 1rem;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-bottom: 1px solid #E1E2DE;
  }

  .mobile-header-search {
    border-top: 1px solid #EEE;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E1E2DE;
    min-width: 100%;
  }

  .dropdown-menu {
    border-radius: 0;
    border-left: none;
    border-right: none;

    .dropdown-item {
      text-align: left;
      padding-left: 20px !important;
      padding-right: 20px !important;
      font-size: 01rem;
    }
  }

  .icon-only-mobile {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.5rem;

    i {
      margin-top: 10px;
      font-size: 2rem;
    }
  }

  .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.osahan-nav .mobile-nav .nav-link {
  width: 100%;

  &.icon-only {
    width: 33%;
    text-align: center;
    font-size: 1.5rem;
    margin-left: 0 !important;
  }

  &.login-nav-item {
    padding-top: 25px !important;
    width: 50%;
  }

  &.searchIcon {
    display: none;
  }
}

.osahan-nav .mobile-nav .nav-item {
  &.login-nav-item {
    padding-top: 15px;
    width: 33%;

    .nav-link {
      border-bottom: none;
    }
  }
}

/*Responsive*/
@media (max-width: 991px) {
  h1 {
    font-size: 35px;
    line-height: 40px;
    hyphens: auto;
  }
  #big .item img {
    background: #FFF;
    height: 300px;
    width: 100%;
    object-fit: contain;
  }
  .osahan-account-page-left .nav-link {
    padding: 5px 10px;
  }
  .rs-steps-vertical {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: white;
    border-radius: 15px;
    padding: 1em;
  }
  .rs-steps-item-tail {
    display: none;
  }
  .rs-steps-item {
    width: 50%;
    margin-top: 0 !important;
  }
  .rs-steps-item-content {
    width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  #big .item img {
    background: #FFF;
    height: 250px;
    width: 100%;
    object-fit: contain;
  }
  .osahan-nav .mobile-nav .nav-link {
    width: 100%;

    &.icon-only {
      width: 100%;
    }

    &.login-nav-item {
      padding-top: 15px !important;
      width: 100%;
      border-bottom: 1px solid #E1E2DE;
    }
  }
  .osahan-nav .mobile-nav .nav-item {
    &.login-nav-item {
      padding-top: 10px;
      width: 100%;
      border-bottom: 1px solid #E1E2DE;
    }
  }
}

.owl-theme .owl-nav [class*="owl-"] i {
  font-size: 15px !important;
  font-weight: 900 !important;
  color: white;
}

.owl-theme .owl-nav [class*="owl-"] {
  background: transparentize($primary, 0.1) !important;
  opacity: 1 !important;
}

.owl-theme .owl-nav .owl-prev {
  margin-left: -8px !important;

  &.disabled {
    opacity: 0.5 !important;
  }
}

.owl-theme .owl-nav .owl-next {
  margin-right: -8px !important;

  &.disabled {
    opacity: 0.5 !important;
  }
}

.credit-container {
  align-items: center;
  background: transparentize(black, 0.2);
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 5px;
  border-radius: 30px;
  line-height: 1.5rem;
  max-height: 42px;
  overflow: hidden;

  .credit-icon {
    font-size: 1rem;
    line-height: 0.2;
    cursor: pointer;
  }

  .credit-desc {
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;

    .inner {
      padding: 5px 10px;
    }
  }
}

.credit-icon-for-Overlay {
  background: transparentize(black, 0.2);
  position: absolute;
  right: 7px;
  top: 15px;
  padding: 5px;
  border-radius: 30px;
  line-height: 0.6rem;
  max-height: 42px;
  overflow: hidden;
  font-size: 1rem;
  line-height: 0.1;
  cursor: pointer;
  color: #FFF;
  z-index: 9;
}

.credit-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparentize(black, 0.1);
  color: #FFF;
  z-index: 999;
  padding: 3rem;
  border-radius: 1rem;
  width: 600px;
  max-width: 100%;

  p {
    color: #FFF;
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.info-box-container {
  background: transparentize(black, 0.2);
  position: absolute;
  left: 15px;
  bottom: 15px;
  padding: 5px;
  border-radius: 1rem;
  line-height: 1.5rem;
  z-index: 1;
  text-align: left;
  max-height: 90%;
  overflow: hidden;

  .info-box-icon {
    font-size: 1rem;
    line-height: 0.2;
    cursor: pointer;
    text-align: left;
  }

  .info-box-desc {
    position: relative;
    font-size: 1rem;
    overflow: hidden;
    max-height: 40vh;

    .inner {
      max-height: 20vh;
      padding: 5px 10px;
      margin-bottom: 10px;
      overflow: auto;

      img {
        min-height: auto;
      }
    }
  }
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $primary;
}

.rs-picker-value-count {
  margin: 0 4px;
  background: $primary;
  color: #FFF;
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: $primary;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: $primary;
  background-color: #F2FAFF;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #FFF;
  border-color: $primary;
  background-color: $primary;
}

.rs-picker-disabled {
  opacity: 0.8;
}


.gastromenu-devider {
  padding: 16px 0;
  font-weight: bold;
}

//.gastro-price{
//  position: relative;
//  b{
//    background: white;
//    z-index: 2;
//    position: relative;
//  }
//}
//.gastro-price:before{
//  content: '';
//  position: absolute;
//  bottom: 2px;
//  left: 0;
//  width: 100%;
//  height: 0;
//  line-height: 0;
//  border-bottom: 2px dotted #ddd;
//  z-index: 1;
//}


.dsgvo-widget__popup-bg {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0D0A0A;
  opacity: 0.9;
}

.dsgvo-widget__popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  font-size: 13px;
  width: 90%;
  max-width: 900px;
  z-index: 10001;
  color: #7A7A7A;
  background: #FFF;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);

  .dsgvo-widget__popup-welcome {
    width: 100%;
    font-weight: 700;
    font-size: 22px;
  }

  .dsgvo-widget__popup-main {
    display: flex;
    justify-content: space-between;
  }

  .dsgvo-widget__popup-main > div {
    width: 48%;
  }

  .dsgvo-widget__popup-categories-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  .dsgvo-widget__popup-categories-item-name {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: calc(100% - 72px);
  }

  .dsgvo-widget__popup-categories-item-count {
    color: #989898;
    font-size: 11px;
  }
}

.rs-btn-toggle-checked {
  background-color: $primary;
}

.rs-btn-toggle-checked:hover {
  background-color: transparentize($primary, 0.2);
}

.rs-btn-toggle-disabled.rs-btn-toggle-checked, .rs-btn-toggle-disabled.rs-btn-toggle-checked:hover {
  background-color: transparentize($primary, 0.8);
  //background: #747474;
}

.follow-pin.badge-info {
  background-color: $primaryGrey;
}

@media (max-width: 991px) {
  .mobile-nav {
    max-height: 100vh;
  }
}

.vendor-details-title, .product-detail-title, .vendor-text {
  h1, h2, h3, h4, h5, h6 {
    font-family: "Arial Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.product-grid-btn {
  color: $primary;
  font-size: 1.25rem;
  cursor: pointer;
}

#region-news-content {
  .image {
    min-height: auto;
  }
}

.rs-list-item-content {
  .drag-Icon {
    padding-left: 30px;

    &:before {
      content: "\f58e";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-col {
      i, .btn {
        display: none;
      }
    }
  }
}

figure.table {
  table {
    width: 100% !important;
    max-width: 100vw;
    overflow: auto;

    //td, th {
    //  border  : 1px solid #DDD;
    //  padding : 10px 15px;
    //}
  }
}

.custom-page {
  table {
    width: 100% !important;
    max-width: 100vw;
    overflow: auto;

    //td, th {
    //  border  : 1px solid #DDD;
    //  padding : 10px 15px;
    //}
  }
}

.product-card-bottom {
  background: $primary;
  margin-left: -16px;
  margin-right: -16px;
  padding: 5px 15px;
  color: #FFF;
  transition: 0.2s;

  .font-bigger {
    font-size: 18px;

    i {
      font-size: 20px;
    }
  }

  .price-text {
    font-size: 16px;
    max-height: 50px;
    transition: 0.2s;
    overflow: hidden;

    &:hover {
      max-height: 250px;
    }
  }
}

.card-basket-icon {
  color: #FFF !important;
  transition: 0.3s;

  &:hover {
    color: $primaryLight !important;
  }
}

.dzu-currentFiles {
  .rs-list-item {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
}

.previewWindow-container {
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 999999999;

  .previewWindow {
    height: 100%;
    overflow: auto;
    background: $lightGreenBackground url("../assets/img/bg_seamless.png") top left repeat;
    background-size: 50%;
    position: relative;
    padding: 15px;
    z-index: 9999999;

    .close-preview {
      position: fixed;
      right: 45px;
      top: 25px;
      border: 1px solid #F32129;
      padding: 10px;
      border-radius: 5px;
      line-height: 1;
      font-size: 16px;
      font-weight: bold;
      color: #FFF;
      cursor: pointer;
      background: #F32129;
      z-index: 999999;

    }
  }
}

.normal-text-field {
  height: auto !important;
  padding-top: 0 !important;
}

.custom-reg-btn {
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  border-radius: 30px;
  transition: 0.3s;

  &:hover {
    background-color: $primaryLight !important;
  }
}

.login-nav-item .dropdown-toggle span {
  max-width: 125px;
  overflow: hidden;
  display: inline-flex;
}


@media (max-width: 991px) {
  .bg-photo {
    .header-bg {
      height: 32vh;
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-container {
      padding: 0 20px;
    }
  }
  .restaurant-detailed-banner .img-fluid.cover {
    height: 32vh;
    object-fit: cover;
    width: 100%;
  }
}

.use-isidora {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial Nova', cursive !important;
    font-weight: 600 !important;
    font-style: normal !important;
  }
}

.to-vendor-news-link {
  a {
    font-size: 18px;
    font-weight: bold;
  }
}

.custom-location-field-holder {
  display: inline-block;
  width: 60%;
}


btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.teaser-sort-holder {
  font-size: 16px;

  span {
    font-weight: 600;
  }

  .rs-radio-wrapper::before {
    border: 1px solid $primary !important;
  }

  .rs-radio-wrapper .rs-radio-inner::before {
    border: 1px solid $primary !important;
  }

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: $primary !important;
    background-color: $primary !important;
  }

  .rs-radio-checked label {
    font-weight: 500;
  }
}

.favourite-filter {
  font-size: 16px;

  span {
    font-weight: 400;

    &.bolder-label {
      font-weight: 500;
    }
  }

  i {
    font-size: 1.5rem;
    margin-top: -2px;
  }
}

@media (max-width: 576px) {
  //.teaser-sort-holder {
  //width           : 100%;
  //display         : flex;
  //justify-content : center;
  //}
  //.favourite-filter {
  //width           : 100%;
  //display         : flex;
  //justify-content : flex-end;
  //border-top      : 1px solid transparentize($textLight, 0.8);
  //padding-top     : 5px;
  //
  //.cursor-pointer {
  //  padding-top : 5px;
  //}
  //}
}

.region-cat-holder {
  .hidden-until-mobile {
    text-align: center;
    cursor: pointer;
    display: none;

    span {
      color: $primary;
    }
  }
}

@media (max-width: 1199px) {
  .region-cat-holder {
    padding-bottom: 15px !important;

    .category-icon {
      transition: all 0.3s ease-in-out;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
    }

    .hidden-until-mobile {
      padding-top: 7px;
      padding-bottom: 7px;
      display: block;
    }

    .category-hidden-mobile {
      display: none;
    }

    &.show-all-mobile-hidden {
      .category-hidden-mobile {
        display: block;
      }
    }
  }
}

.menuplan-card {
  .list-card-image {
    padding-top: 35px;
    padding-bottom: 0;
    position: relative;

    .menu-badge {
      display: flex;
      justify-content: center;

      .menu-badge-inner {
        background: #CD1719;
        color: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          display: block;
          margin-left: -3px;
        }
      }
    }

    .vendor-title {
      margin-top: 0;
      text-align: center;
      font-size: 24px;
    }
  }

  .desc-p {
    white-space: pre-line;
    text-align: center;
  }

  .main-div {
    padding-bottom: 20px;
    position: relative;
  }

  .list-card-image::before {
    content: '';
    width: calc(100% - 30px);
    height: 15px;
    //border-bottom: 2px dashed transparentize($primary,0.7);
    background-image: url("../assets/img/trennlinie_regionline_oben.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .product-card-bottom {
    position: relative;
  }

  .product-card-bottom::before {
    content: "";
    width: calc(100% - 30px);
    height: 15px;
    background-size: 100%;
    background-repeat: no-repeat;
    //border-bottom: 2px dashed transparentize($primary,0.7);
    background-image: url("../assets/img/trennlinie_regionline_oben.svg");
    position: absolute;
    left: 15px;
    top: -30px;
    transform: rotate(180deg);
  }
}

/*
* NEW RESPONSIVE
*/
.list-card-body {
  .card-body-text {
    padding-bottom: 7.5px;
  }

  .header-small {
    font-size: 13px;
  }

  .card-title-header {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
}

.top-footer {
  h5 {
    font-size: 20px;
  }
}


@media (max-width: 767px) {
  .list-card-body {
    .card-title-header {
      font-size: 18px;
      margin-bottom: 10px !important;
    }
  }
  .navbar-brand {
    a {
      img {
        max-height: 35px !important;
      }
    }
  }
  .custom-location-field-holder {
    width: 100%;
  }
  .locate-me {
    display: flex;
    align-items: center;
    margin-top: 3px;

    span {
      display: none;
    }
  }
  .homepage-search-block {
    padding: 30px 0 !important;

    .homepage-search-title {
      margin-bottom: 0 !important;

      h1 {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 0 !important;
      }
    }

    .homepage-search-form {
      .form-group {
        margin-bottom: 7.5px;
      }

      .location-field {
        margin-bottom: 7.5px;
      }
    }
  }
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .section-header {
    .line {
      margin-bottom: 10px !important;
    }
  }
  .blackboard {
    min-height: 350px;

    .big-text {
      font-size: 1.4rem;
    }
  }
  .section {
    &.pt-5 {
      padding-top: 30px !important;
    }

    &.pb-5 {
      padding-bottom: 30px !important;
    }
  }
  p {
    font-size: 16px;
  }
  .list-card-body {
    .card-title-header {
      font-size: 18px;
    }
  }
  .footer {
    .reg-text {
      text-align: center;
      margin-bottom: 15px;

      .link-p {
        font-size: 16px !important;
      }
    }
  }
  .category-icon {
    .cat-title {
      font-size: 14px;
    }
  }
}

.rs-picker-toolbar-right-btn-ok {
  background-color: $primary;

  &:hover {
    background-color: $primaryDark !important;
  }
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell, .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: $primary;
}

.prev-event-image {
  img {
    max-height: 400px;
    min-width: 400px;
    object-fit: contain;
  }
}

.events-holder {
  padding: 30px 15px;
  background: #FFF;
  border-radius: 15px;

  .show-more-button {
    color: $primary;
  }

  .event-category {
    margin-bottom: 10px;

    .event-category-header {
      .header-info {
        padding: 0 7px;
        color: #313131;
        font-size: 16px;
        display: block;
        width: 100%;
      }

      .header-info-text {
        font-size: 15px;
      }
    }
  }


  .event-container {
    margin-bottom: 2px;

    .event-header {
      background: transparentize($primary, 0.7);
      font-size: 13px;
      color: #313131;
      font-weight: 600;

      .header-info-text {
        font-size: 13px;
      }

      .col-header {
        border-left: 1px solid #FFF;
        border-right: 1px solid #FFF;
      }

      .col-header-first {
        border-left: 1px solid transparent;
        border-right: 1px solid #FFF;
      }

      .col-header-last {
        border-left: 1px solid #FFF;
        border-right: 1px solid transparent;
      }

      .header-info-text {
        padding: 5px 7px;
        padding-top: 7px;
        display: block;

        a {
          background: $primary;
          height: 18px;
          width: 18px;
          display: inline-block;
          border-radius: 100%;
          text-align: center;

          i {
            color: #FFF !important;
            font-size: 13px;
            margin-top: 2px;
          }
        }
      }

      .header-info {
        padding: 5px 7px;
        padding-top: 7px;
        display: none;
      }
    }

    .event-body {
      height: 0;
      overflow: hidden;
      background: transparentize($primary, 0.9);

      &.active {
        padding: 15px 7px;
        height: auto;
      }
    }
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.vendor-event-image-holder {
  img {
    max-height: 150px;
    object-fit: contain;
  }
}

.event-category-title {
  font-size: 16px;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .col-header-first, .col-header-last, .col-header {
    padding-top: 2.5px;
    padding-bottom: 2.5px;

    &.no-padding-bottom {
      padding-bottom: 0 !important;
    }

    &.no-padding-top {
      padding-top: 0 !important;
    }
  }
  .header-info, .header-info-text {
    padding: 1px 2px !important;
    padding-top: 3px;
    line-height: 1;
  }
  .header-info {
    padding-left: 10px !important;
  }
  .header-info-text {
    padding-right: 10px !important;
  }
  .event-no-border-left {
    border-left: none !important;
  }
  .open-event-button {
    display: block !important;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 20px;
    border: none !important;
  }
  .events-holder {
    .event-category-header {
      display: none;
    }

    .event-container {
      margin-bottom: 5px;

      .event-header {
        .header-info {
          display: inline-block;
        }

        .header-info-text {
          display: inline-block;
        }
      }
    }
  }
  .header-info-text-bold-mobile {
    font-weight: bold;
  }
}

@media (max-width: 1400px) and (min-width: 1199px) {
  .category-icon {
    .cat-title {
      font-size: 13px;
    }
  }
}

.header-spacer {
  height: 75px;
}

.contact-icon-holder {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  display: flex;

  &.show-border {
    border-left: 1px solid #FFF;
  }
}

.contact-icon {
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $primary;

  i {
    font-size: 18px;
    color: #FFF;
  }

  &.whatsapp {
    background: #25D366;
  }

  &.telegram {
    background: #08C;
  }

  &.fb {
    background: #3B5998;
  }

  &.insta {
    background: #F09433;
    background: -moz-linear-gradient(45deg, #F09433 0%, #E6683C 25%, #DC2743 50%, #CC2366 75%, #BC1888 100%);
    background: -webkit-linear-gradient(45deg, #F09433 0%, #E6683C 25%, #DC2743 50%, #CC2366 75%, #BC1888 100%);
    background: linear-gradient(45deg, #F09433 0%, #E6683C 25%, #DC2743 50%, #CC2366 75%, #BC1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  }

  &.fb-m {
    background: #0078FF;
  }

  &.youtube {
    background: #F00;
  }

  &.twitter {
    background: #1DA1F2;
  }

  &.linkedin {
    background: #0E76A8
  }

  &.xing {
    background: #126567;
  }

  &.pinterest {
    background: #C8232C;
  }
}

.job-vendor-title {
  display: flex;
  padding: 50px 15px;
  justify-content: center;
  align-items: center;

  span {
    color: $text;
    font-size: 1.5rem;
    line-height: 1.1;
    text-align: center;
    display: block;
    word-break: break-word;
  }
}

.product-price-info-text {
  display: block;
  font-size: 11px;
}

.region-category-anchor {
  text-align: center;
  padding: 10px;
  padding-top: 12px;
  border-radius: 50px;
  background: $primary;
  color: #FFF !important;
  width: 100%;
  display: block;
  font-size: 18px;
  transition: 0.3s;
  border: 1px solid $primary;

  &:hover {
    background: $primaryDark;
  }
}

.add-post-link {
  position: absolute;
  right: 0;
  top: 15px;
  display: block;
  width: 30px;
  height: 30px;
  background: $primary;
  color: $white !important;
  text-align: center;
  border-radius: 50%;
  transition: 0.3s;

  i {
    margin-top: 3px;
    font-size: 1.5rem;
  }

  &:hover {
    background: $primaryDark;
  }
}

.search-mobile-icon {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 1.25rem;
  background: #FFF;
  z-index: 9;
}


.linkbar {
  padding: 15px 0;
  background: $primary;
  color: #FFF;

  .lable {
    margin-right: 15px;
  }

  .link-buttons {
    .link-button {
      display: inline-block;
      color: #FFF;
      border: 1px solid #FFF;
      border-radius: 15px;
      margin: 0 7.5px;
      padding: 5px 15px;
      text-align: center;
      min-width: 85px;
    }
  }

  .mobile-bar {
    display: none;

    .lable {
      cursor: pointer;

      .open-icon, .arrow {
        font-size: 1.5rem;
        transition: all 0.3s ease-in-out;
      }

      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .lable-text {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-top: 3px;
          margin-left: 5px;
        }
      }

      &.active {
        .arrow {
          transform: rotate(90deg);
        }
      }
    }

    .link-buttons {
      padding: 10px 0;

      .link-button {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 991px) {
    .desktop-bar {
      display: none !important;
    }
    .mobile-bar {
      display: block;
    }
  }
}

.owl-theme .owl-dots span {
  width: 10px !important;
  height: 10px !important;
  margin: 3px 3px !important;
  border: 1px solid $primary;
  background: transparent !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $primary !important;
}

.products-section {
  @media (max-width: 599px) {
    .container-fluid {
      padding: 0;
    }
    .row {
      margin: 0;

      .col-lg-12 {
        padding: 0;
      }
    }
    .col-lg-3,
    .col-lg-4,
    .col-md-12 {
      padding: 0;
    }
    .product-owl-holder {
      padding: 0;

      &.scrollDots {
        .owl-dots {
          white-space: nowrap;
          overflow-x: auto;
        }
      }

      .owl-item {
        .item {
          padding: 7px 0;

          .list-card.rounded {
            border-radius: 20px !important;
          }
        }
      }
    }
  }
}


.footer {
  background: $primary;

  p, a, h1, h2, h3, h4, h5, h6, li {
    color: #FFF;
  }

  a:hover {
    text-decoration: underline !important;
  }

  li {
    a {
      color: #FFF !important;
    }
  }
}

.moblie-ovleray {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  z-index: 99999999;

  .fields {
    .searchimput-group {
      .form-control {
        border: 2px solid $primary;
      }

      i {
        position: absolute;
        right: 22px;
        top: 6px;
        font-size: 1.75rem;
        cursor: pointer;
        z-index: 9999;
      }
    }

    .close-overlay {
      color: $primary;
      font-size: 2rem;
      cursor: pointer;
    }

    .location-search-input {
      border: 2px solid $primary;
    }

    .locate-me {
      top: 3px;
    }
  }
}

@media (max-width: 991px) {
  .card-overlay {
    height: calc(100% - 100px);
    margin-top: 100px;
  }
  .bookmark-overlay {
    height: calc(100% - 100px) !important;
    margin-top: 100px !important;
  }
  .moblie-ovleray {
    height: calc(100% - 100px) !important;
    top: 100px;
  }
}

@media (max-width: 767px) {
  .card-overlay {
    height: calc(100% - 75px);
    margin-top: 75px;
  }
  .bookmark-overlay {
    height: calc(100% - 75px) !important;
    margin-top: 75px !important;
  }
  .moblie-ovleray {
    height: calc(100% - 75px) !important;
    top: 75px;
  }
}

.mobile-nav-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .nav-link {
    padding: 5px !important;
    border: unset !important;

    &.icon-only {
      padding: 5px !important;
      margin-left: 0 !important;

      i {
        margin-top: 0 !important;
      }
    }

    color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: rgba(0, 0, 0, 0.75);
    }

    i {
      font-size: 1.5rem;
    }
  }

  .mobile-basket {
    position: relative;

    .badge-success {
      margin-left: 0 !important;
      position: absolute;
      top: 5px;
      right: 0;
    }
  }

  .dropdown-menu {
    a {
      text-align: left;
    }
  }
}


.mobile-nav {
  background: $primary;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .login-nav-item {
    width: 100% !important;

    .dropdown-menu {
      background: $primaryLight;
    }
  }

  .close-nav-mobile {
    font-size: 2rem;
    cursor: pointer;
    color: #FFF;
  }

  a.nav-link {
    color: #FFF !important;
    border: unset !important;
    padding: 5px !important;
  }

  a.mobile-footer-links {
    color: #000 !important;
  }
}

.main-region-content {
  .nav-tabs {
    display: none;
  }
}

@media (max-width: 767px) {
  .main-region-content {
    padding: 0;
  }
}

.mobile-categories {
  &.open {
    height: calc(100vh - 120px);
  }

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: none;

  .mobile-cat-label {
    -webkit-box-shadow: 0px -2px 15px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0px -2px 15px 2px rgba(0, 0, 0, 0.4);
    padding: 15px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    align-items: center;
    color: $primary;
    cursor: pointer;

    i {
      font-size: 2rem;
    }
  }

  .region-cat-holder {
    border-radius: 0 !important;
    height: 80vh;
    overflow: auto;
  }

  .cats-holder {
    background: #FFF;
  }
}

@media (max-width: 1199px) {
  .mobile-categories {
    display: block;
  }
}


.location-field-holder {
  background: #FFF;
  padding: 15px 0;
  z-index: 1;

  .location-field {
    .location-search-input {
      border: 1px solid $primary;
    }

    .locate-me {
      top: 3px;
      right: 18px;
    }
  }
}

.main-region-content {
  @media (min-width: 1200px) {
    .product-owl-holder {
      padding: 0 7px !important;
    }
  }
}

.text-yellow {
  color: #FFD700;
}

@media (max-width: 991px) {
  .main-region-content {
    .tab-pane.special {
      .products-section {
        .container-fluid {
          padding: 0;

          .list-card.rounded {
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}

.shop-item-card {
  @media (max-width: 576px) {
    .product-card-img {
      height: 150px;
    }
    .list-card-body .card-title-header {
      font-size: 12px;
    }
    .product-card-bottom .font-bigger, .product-card-bottom {
      font-size: 12px;
      padding: 5px !important;
    }
    .card-basket-icon {
      display: none;
    }
    .list-card-body .header-small {
      font-size: 10px;
      line-height: 1;
      margin-bottom: 2px;
    }
    .list-card-body .card-body-text {
      padding: 2px 0;
    }
  }
}

#thumbs .item {
  background: transparent !important;
}

.region-cat-holder {
  position: sticky;
  top: 160px;
}

.marketplace-filter {
  position: sticky;
  top: 100px;
  overflow-y: scroll;
}

.vendor-filter {
  position: sticky;
  top: 100px;
}

.product-card-wrapper {
  transition: opacity 0.5s;
  opacity: 1;
}

.distance-text {
  font-size: 14px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

/** Scrollbar **/
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryLight;
}

.rrt-success {
  background-color: $primary !important;
}

.horizontal-scroll {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex-direction: row;
  flex-wrap: nowrap;
}

#thumbs .item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 576px) {
  .product-card-img {
    height: 200px;
  }
}

@media (max-width: 440px) {
  #thumbs .item {
    height: 60px;
    width: 60px;

    img {
      height: 60px;
      width: 60px;
    }
  }
}
