@import "../../theme/colors";

.card-item-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;

  .card-basket-icon {
    margin-left: 0.5rem;
  }
}
