@import "../../theme/colors.scss";

.location-field {
  position: relative;

  .locate-me {
    background: #ECECEC none repeat scroll 0 0;
    color: #666;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: 20px;
    position: absolute;
    right: 14px;
    top: 9px;
    border: none;

    &:hover {
      background: #666 none repeat scroll 0 0;
      color: $white;
    }
  }

  .location-search-input,
  .location-search-input:focus,
  .location-search-input:active {
    border: none;
    padding: 16px;
    outline: none;


  }


  .autocomplete-dropdown-container {
    border: none;
    position: absolute;
    z-index: 9000;
    width: calc(100% - 2em - 40px);
    background-color: white;
    margin-left: 18px;
  }


}

.location-field-form {
  position: relative;
  margin-bottom: 1rem;
  border-bottom: #777;


  .form-control {
    font-size: 13px;
    min-height: 38px;
    border-bottom: transparent !important;
  }

}


.location-search-input[aria-expanded="true"] + .autocomplete-dropdown-container {
  border: 1px solid $primary;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #FFF;
  cursor: pointer;
  border-radius: 1em;


  &.active {
    background-color: $lightGreenBackground !important;
  }
}
