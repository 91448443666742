.horizontal-scroll {
  .news-card-item-wrapper,
  .news-card-item {
    width: 25em;
  }
}

.news-card-item-wrapper {
  &.job {
    .list-card-image {
      min-height: 6em;
    }
  }

  .list-card-image {
    min-height: 6em;
  }
}
