@import "../../theme/colors";

.card-basket-ic {
  color: black !important;
  transition: 0.3s;
  padding: 0 !important;

  &:hover {
    color: $primaryLight !important;
  }
}

.horizontal-scroll {
  .product-card-item-wrapper,
  .product-card-item {
    width: 25em;
  }
}

.product-card-item-wrapper {
  .request-link {
    color: $white;
    font-size: 1.2em;
    font-weight: bold;

    &:hover {
      color: $primaryLight !important;
    }
  }
}
