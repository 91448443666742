@import "../../theme/colors";

.sliding-panel-container {
  &.active {
    background-color: transparent;
  }

  .panel-content {
    height: calc(100% - 75px);
  }

  .panel {
    overflow-x: hidden !important;
  }
}

.bookmark-overlay {
  margin-top: 75px;
  background: #FFF;
  height: 100%;

  .close-card {
    text-align: left;

    i {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .sliding-panel-container {
    height: 100%;

    .panel-container-right-enter-done {
      height: 100%;
    }

    .panel-content {
      height: 100%;
    }

    .glass, .panel {
      height: 100% !important;
    }
  }
  .reg-header {
    font-size: 2rem;
  }
}

.add-collection {
  background: $primary;
  border-radius: 3rem;
  color: $white;
  padding: 10px;
  margin-left: 5px;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 2rem;
}

@media (max-width: 991px) {
  .bookmark-overlay {
    height: calc(100% - 100px) !important;
    margin-top: 100px !important;
  }
}

@media (max-width: 767px) {
  .bookmark-overlay {
    height: calc(100% - 75px) !important;
    margin-top: 75px !important;
  }
}
