// variables.scss
$white: #FFF;
$black: #000;
$lightBackground: #F3F7F8;
$text: #3c3c3f;
$textLight: #3c3c3f;
$primary: #618C03;
$primaryLight: #A5BF6B;
$primaryNeon: #97BF04;
$primaryDark: #365902;
$red: #FF3008;
$danger: #F32129;
$primaryLight2: #d2ea9c;
$primaryGrey: #3c3c3f;
$brown: #e2d3bc;
$lightGray: #CED4DA;
$lightGreen: #e6f3db;
$secondaryColor: #f7fbf3;
$primaryColor: #59953c;

$lightGreenBackground: mix($primary, $white, 5);
$formFieldBorder: $lightGray;
// the :export directive is the magic sauce for webpack
//noinspection ALL
:export {
  white: $white;
  lightBackground: $lightBackground;
  text: $text;
  primary: $primary;
  primaryLight: $primaryLight;
  primaryNeon: $primaryNeon;
  primaryDark: $primaryDark;

}
