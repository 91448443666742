@import "../../theme/colors";

#big .item {
  margin: 2px;
  border-radius: 3px;
}

#big .item img {
  background: #FFF;
  height: 500px;
  width: 100%;
  object-fit: contain;
}

#thumbs .item {
  background: $text;
  height: 100px;
  padding: 0;
  margin: 2px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-label {
  margin-bottom: 0;
  margin-top: 2px;
  font-size: 13.5px;
  font-weight: 400;
}

.restaurant-detailed-header-left {
  word-break: break-word;
}

.notAvailable {
  color: black;
  font-size: 17px;
  text-align: center;
}
