.back-to-home-wrapper {
  display: flex;
  justify-content: center;

  .back-to-home-button {
    margin-top: 2em;
    text-align: center;
    color: white;
  }
}
