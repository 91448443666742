@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-RegularIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-RegularIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-RegularIt.ttf') format('truetype');
  font-weight  : 400;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-BoldIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-BoldIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-BoldIt.ttf') format('truetype');
  font-weight  : 700;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-Bold.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-Bold.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-Bold.ttf') format('truetype');
  font-weight  : 700;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-BlackIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-BlackIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-BlackIt.ttf') format('truetype');
  font-weight  : 900;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-Black.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-Black.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-Black.ttf') format('truetype');
  font-weight  : 900;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-Light.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-Light.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-Light.ttf') format('truetype');
  font-weight  : 300;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-LightIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-LightIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-LightIt.ttf') format('truetype');
  font-weight  : 300;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-SemiBold.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-SemiBold.ttf') format('truetype');
  font-weight  : 600;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-Medium.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-Medium.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-Medium.ttf') format('truetype');
  font-weight  : 500;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-SemiBoldIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-SemiBoldIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-SemiBoldIt.ttf') format('truetype');
  font-weight  : 600;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora SemiBold';
  src          : url('../assets/fonts/IsidoraAlt-SemiBoldIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-SemiBoldIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-SemiBoldIt.ttf') format('truetype');
  font-weight  : 600;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-Regular.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-Regular.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-Regular.ttf') format('truetype');
  font-weight  : normal;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Isidora Alt';
  src          : url('../assets/fonts/IsidoraAlt-MediumIt.woff2') format('woff2'),
  url('../assets/fonts/IsidoraAlt-MediumIt.woff') format('woff'),
  url('../assets/fonts/IsidoraAlt-MediumIt.ttf') format('truetype');
  font-weight  : 500;
  font-style   : italic;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-Heavy.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-Heavy.woff') format('woff'),
  url('../assets/fonts/NexaScript-Heavy.ttf') format('truetype');
  font-weight  : 900;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-Bold.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-Bold.woff') format('woff'),
  url('../assets/fonts/NexaScript-Bold.ttf') format('truetype');
  font-weight  : 700;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-SemiBold.woff') format('woff'),
  url('../assets/fonts/NexaScript-SemiBold.ttf') format('truetype');
  font-weight  : 600;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-Regular.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-Regular.woff') format('woff'),
  url('../assets/fonts/NexaScript-Regular.ttf') format('truetype');
  font-weight  : 400;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-Light.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-Light.woff') format('woff'),
  url('../assets/fonts/NexaScript-Light.ttf') format('truetype');
  font-weight  : 300;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Nexa Script';
  src          : url('../assets/fonts/NexaScript-Thin.woff2') format('woff2'),
  url('../assets/fonts/NexaScript-Thin.woff') format('woff'),
  url('../assets/fonts/NexaScript-Thin.ttf') format('truetype');
  font-weight  : 100;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Arial Nova';
  src          : url('../assets/fonts/ArialNova.ttf') format('truetype');
  font-weight  : 500;
  font-style   : normal;
  font-display : swap;
}

@font-face {
  font-family  : 'Arial Nova';
  src          : url('../assets/fonts/ArialNova-Bold.ttf') format('truetype');
  font-weight  : 700;
  font-style   : normal;
  font-display : swap;
}

