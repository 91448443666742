@import "../../theme/colors";

.job-card-image-container {
  position: relative;
  padding: 1.5em;
}

.job-card-image {
  object-fit: contain;
  height: 100px;
  width: 100%;
  object-position: center;
  /*width: 100%;
  object-position: top center;
  object-fit: cover;*/
}

.job-card-bottom {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.job-card-body-content {
  justify-content: flex-start;
}

.job-card-grey-text {
  color: $textLight !important;
}

.horizontal-scroll {
  .job-card-item-wrapper,
  .job-card-item {
    width: 25em;
  }
}
