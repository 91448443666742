@import "../../theme/colors";

.sozial-share-modal {
  .modal-header {
    padding: 1em;
  }

  .modal-content {
    border-radius: 1em !important;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .modal-body {
    width: 100% !important;
  }

  .modal-body-wrapper {
    justify-content: center !important;
    flex-direction: column;

    .title {
      text-align: center;
      font-size: 1rem;
      padding: 0.5em;
    }

    .preview-image {
      margin-bottom: 1.5rem;
      margin-top: 1rem;
      height: 10rem;
      object-fit: contain;
    }
  }

  .modal-title {
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
    text-overflow: ellipsis;
    font-size: 1.2em;
    vertical-align: middle;
  }

  .share-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .copy-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5em;

    .url-form {
      margin-right: 1em;
    }
  }
}
