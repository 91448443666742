@import "../../theme/colors";

.social-share-wrapper {
  flex-wrap: nowrap;
  align-self: center !important;

  .card-basket-ic {
    stroke: white !important;
    transition: 0.3s;
    padding: 0 !important;

    &:hover {
      opacity: 0.7;
    }
  }

  .share-icon {
    height: 24px;
    stroke: white !important;
    fill: white !important;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  .btn-primary:focus {
    box-shadow: none !important;
  }

  #Ebene_1 {
    &:focus {
      outline: none !important;
    }
  }
}
